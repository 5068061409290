import { createAggregatedClient } from "@smithy/smithy-client";
import { CognitoIdentityProviderClient } from "./CognitoIdentityProviderClient";
import { AddCustomAttributesCommand, } from "./commands/AddCustomAttributesCommand";
import { AdminAddUserToGroupCommand, } from "./commands/AdminAddUserToGroupCommand";
import { AdminConfirmSignUpCommand, } from "./commands/AdminConfirmSignUpCommand";
import { AdminCreateUserCommand, } from "./commands/AdminCreateUserCommand";
import { AdminDeleteUserAttributesCommand, } from "./commands/AdminDeleteUserAttributesCommand";
import { AdminDeleteUserCommand, } from "./commands/AdminDeleteUserCommand";
import { AdminDisableProviderForUserCommand, } from "./commands/AdminDisableProviderForUserCommand";
import { AdminDisableUserCommand, } from "./commands/AdminDisableUserCommand";
import { AdminEnableUserCommand, } from "./commands/AdminEnableUserCommand";
import { AdminForgetDeviceCommand, } from "./commands/AdminForgetDeviceCommand";
import { AdminGetDeviceCommand, } from "./commands/AdminGetDeviceCommand";
import { AdminGetUserCommand, } from "./commands/AdminGetUserCommand";
import { AdminInitiateAuthCommand, } from "./commands/AdminInitiateAuthCommand";
import { AdminLinkProviderForUserCommand, } from "./commands/AdminLinkProviderForUserCommand";
import { AdminListDevicesCommand, } from "./commands/AdminListDevicesCommand";
import { AdminListGroupsForUserCommand, } from "./commands/AdminListGroupsForUserCommand";
import { AdminListUserAuthEventsCommand, } from "./commands/AdminListUserAuthEventsCommand";
import { AdminRemoveUserFromGroupCommand, } from "./commands/AdminRemoveUserFromGroupCommand";
import { AdminResetUserPasswordCommand, } from "./commands/AdminResetUserPasswordCommand";
import { AdminRespondToAuthChallengeCommand, } from "./commands/AdminRespondToAuthChallengeCommand";
import { AdminSetUserMFAPreferenceCommand, } from "./commands/AdminSetUserMFAPreferenceCommand";
import { AdminSetUserPasswordCommand, } from "./commands/AdminSetUserPasswordCommand";
import { AdminSetUserSettingsCommand, } from "./commands/AdminSetUserSettingsCommand";
import { AdminUpdateAuthEventFeedbackCommand, } from "./commands/AdminUpdateAuthEventFeedbackCommand";
import { AdminUpdateDeviceStatusCommand, } from "./commands/AdminUpdateDeviceStatusCommand";
import { AdminUpdateUserAttributesCommand, } from "./commands/AdminUpdateUserAttributesCommand";
import { AdminUserGlobalSignOutCommand, } from "./commands/AdminUserGlobalSignOutCommand";
import { AssociateSoftwareTokenCommand, } from "./commands/AssociateSoftwareTokenCommand";
import { ChangePasswordCommand, } from "./commands/ChangePasswordCommand";
import { ConfirmDeviceCommand, } from "./commands/ConfirmDeviceCommand";
import { ConfirmForgotPasswordCommand, } from "./commands/ConfirmForgotPasswordCommand";
import { ConfirmSignUpCommand, } from "./commands/ConfirmSignUpCommand";
import { CreateGroupCommand } from "./commands/CreateGroupCommand";
import { CreateIdentityProviderCommand, } from "./commands/CreateIdentityProviderCommand";
import { CreateResourceServerCommand, } from "./commands/CreateResourceServerCommand";
import { CreateUserImportJobCommand, } from "./commands/CreateUserImportJobCommand";
import { CreateUserPoolClientCommand, } from "./commands/CreateUserPoolClientCommand";
import { CreateUserPoolCommand, } from "./commands/CreateUserPoolCommand";
import { CreateUserPoolDomainCommand, } from "./commands/CreateUserPoolDomainCommand";
import { DeleteGroupCommand } from "./commands/DeleteGroupCommand";
import { DeleteIdentityProviderCommand, } from "./commands/DeleteIdentityProviderCommand";
import { DeleteResourceServerCommand, } from "./commands/DeleteResourceServerCommand";
import { DeleteUserAttributesCommand, } from "./commands/DeleteUserAttributesCommand";
import { DeleteUserCommand } from "./commands/DeleteUserCommand";
import { DeleteUserPoolClientCommand, } from "./commands/DeleteUserPoolClientCommand";
import { DeleteUserPoolCommand, } from "./commands/DeleteUserPoolCommand";
import { DeleteUserPoolDomainCommand, } from "./commands/DeleteUserPoolDomainCommand";
import { DescribeIdentityProviderCommand, } from "./commands/DescribeIdentityProviderCommand";
import { DescribeResourceServerCommand, } from "./commands/DescribeResourceServerCommand";
import { DescribeRiskConfigurationCommand, } from "./commands/DescribeRiskConfigurationCommand";
import { DescribeUserImportJobCommand, } from "./commands/DescribeUserImportJobCommand";
import { DescribeUserPoolClientCommand, } from "./commands/DescribeUserPoolClientCommand";
import { DescribeUserPoolCommand, } from "./commands/DescribeUserPoolCommand";
import { DescribeUserPoolDomainCommand, } from "./commands/DescribeUserPoolDomainCommand";
import { ForgetDeviceCommand, } from "./commands/ForgetDeviceCommand";
import { ForgotPasswordCommand, } from "./commands/ForgotPasswordCommand";
import { GetCSVHeaderCommand, } from "./commands/GetCSVHeaderCommand";
import { GetDeviceCommand } from "./commands/GetDeviceCommand";
import { GetGroupCommand } from "./commands/GetGroupCommand";
import { GetIdentityProviderByIdentifierCommand, } from "./commands/GetIdentityProviderByIdentifierCommand";
import { GetLogDeliveryConfigurationCommand, } from "./commands/GetLogDeliveryConfigurationCommand";
import { GetSigningCertificateCommand, } from "./commands/GetSigningCertificateCommand";
import { GetUICustomizationCommand, } from "./commands/GetUICustomizationCommand";
import { GetUserAttributeVerificationCodeCommand, } from "./commands/GetUserAttributeVerificationCodeCommand";
import { GetUserCommand } from "./commands/GetUserCommand";
import { GetUserPoolMfaConfigCommand, } from "./commands/GetUserPoolMfaConfigCommand";
import { GlobalSignOutCommand, } from "./commands/GlobalSignOutCommand";
import { InitiateAuthCommand, } from "./commands/InitiateAuthCommand";
import { ListDevicesCommand } from "./commands/ListDevicesCommand";
import { ListGroupsCommand } from "./commands/ListGroupsCommand";
import { ListIdentityProvidersCommand, } from "./commands/ListIdentityProvidersCommand";
import { ListResourceServersCommand, } from "./commands/ListResourceServersCommand";
import { ListTagsForResourceCommand, } from "./commands/ListTagsForResourceCommand";
import { ListUserImportJobsCommand, } from "./commands/ListUserImportJobsCommand";
import { ListUserPoolClientsCommand, } from "./commands/ListUserPoolClientsCommand";
import { ListUserPoolsCommand, } from "./commands/ListUserPoolsCommand";
import { ListUsersCommand } from "./commands/ListUsersCommand";
import { ListUsersInGroupCommand, } from "./commands/ListUsersInGroupCommand";
import { ResendConfirmationCodeCommand, } from "./commands/ResendConfirmationCodeCommand";
import { RespondToAuthChallengeCommand, } from "./commands/RespondToAuthChallengeCommand";
import { RevokeTokenCommand } from "./commands/RevokeTokenCommand";
import { SetLogDeliveryConfigurationCommand, } from "./commands/SetLogDeliveryConfigurationCommand";
import { SetRiskConfigurationCommand, } from "./commands/SetRiskConfigurationCommand";
import { SetUICustomizationCommand, } from "./commands/SetUICustomizationCommand";
import { SetUserMFAPreferenceCommand, } from "./commands/SetUserMFAPreferenceCommand";
import { SetUserPoolMfaConfigCommand, } from "./commands/SetUserPoolMfaConfigCommand";
import { SetUserSettingsCommand, } from "./commands/SetUserSettingsCommand";
import { SignUpCommand } from "./commands/SignUpCommand";
import { StartUserImportJobCommand, } from "./commands/StartUserImportJobCommand";
import { StopUserImportJobCommand, } from "./commands/StopUserImportJobCommand";
import { TagResourceCommand } from "./commands/TagResourceCommand";
import { UntagResourceCommand, } from "./commands/UntagResourceCommand";
import { UpdateAuthEventFeedbackCommand, } from "./commands/UpdateAuthEventFeedbackCommand";
import { UpdateDeviceStatusCommand, } from "./commands/UpdateDeviceStatusCommand";
import { UpdateGroupCommand } from "./commands/UpdateGroupCommand";
import { UpdateIdentityProviderCommand, } from "./commands/UpdateIdentityProviderCommand";
import { UpdateResourceServerCommand, } from "./commands/UpdateResourceServerCommand";
import { UpdateUserAttributesCommand, } from "./commands/UpdateUserAttributesCommand";
import { UpdateUserPoolClientCommand, } from "./commands/UpdateUserPoolClientCommand";
import { UpdateUserPoolCommand, } from "./commands/UpdateUserPoolCommand";
import { UpdateUserPoolDomainCommand, } from "./commands/UpdateUserPoolDomainCommand";
import { VerifySoftwareTokenCommand, } from "./commands/VerifySoftwareTokenCommand";
import { VerifyUserAttributeCommand, } from "./commands/VerifyUserAttributeCommand";
const commands = {
    AddCustomAttributesCommand,
    AdminAddUserToGroupCommand,
    AdminConfirmSignUpCommand,
    AdminCreateUserCommand,
    AdminDeleteUserCommand,
    AdminDeleteUserAttributesCommand,
    AdminDisableProviderForUserCommand,
    AdminDisableUserCommand,
    AdminEnableUserCommand,
    AdminForgetDeviceCommand,
    AdminGetDeviceCommand,
    AdminGetUserCommand,
    AdminInitiateAuthCommand,
    AdminLinkProviderForUserCommand,
    AdminListDevicesCommand,
    AdminListGroupsForUserCommand,
    AdminListUserAuthEventsCommand,
    AdminRemoveUserFromGroupCommand,
    AdminResetUserPasswordCommand,
    AdminRespondToAuthChallengeCommand,
    AdminSetUserMFAPreferenceCommand,
    AdminSetUserPasswordCommand,
    AdminSetUserSettingsCommand,
    AdminUpdateAuthEventFeedbackCommand,
    AdminUpdateDeviceStatusCommand,
    AdminUpdateUserAttributesCommand,
    AdminUserGlobalSignOutCommand,
    AssociateSoftwareTokenCommand,
    ChangePasswordCommand,
    ConfirmDeviceCommand,
    ConfirmForgotPasswordCommand,
    ConfirmSignUpCommand,
    CreateGroupCommand,
    CreateIdentityProviderCommand,
    CreateResourceServerCommand,
    CreateUserImportJobCommand,
    CreateUserPoolCommand,
    CreateUserPoolClientCommand,
    CreateUserPoolDomainCommand,
    DeleteGroupCommand,
    DeleteIdentityProviderCommand,
    DeleteResourceServerCommand,
    DeleteUserCommand,
    DeleteUserAttributesCommand,
    DeleteUserPoolCommand,
    DeleteUserPoolClientCommand,
    DeleteUserPoolDomainCommand,
    DescribeIdentityProviderCommand,
    DescribeResourceServerCommand,
    DescribeRiskConfigurationCommand,
    DescribeUserImportJobCommand,
    DescribeUserPoolCommand,
    DescribeUserPoolClientCommand,
    DescribeUserPoolDomainCommand,
    ForgetDeviceCommand,
    ForgotPasswordCommand,
    GetCSVHeaderCommand,
    GetDeviceCommand,
    GetGroupCommand,
    GetIdentityProviderByIdentifierCommand,
    GetLogDeliveryConfigurationCommand,
    GetSigningCertificateCommand,
    GetUICustomizationCommand,
    GetUserCommand,
    GetUserAttributeVerificationCodeCommand,
    GetUserPoolMfaConfigCommand,
    GlobalSignOutCommand,
    InitiateAuthCommand,
    ListDevicesCommand,
    ListGroupsCommand,
    ListIdentityProvidersCommand,
    ListResourceServersCommand,
    ListTagsForResourceCommand,
    ListUserImportJobsCommand,
    ListUserPoolClientsCommand,
    ListUserPoolsCommand,
    ListUsersCommand,
    ListUsersInGroupCommand,
    ResendConfirmationCodeCommand,
    RespondToAuthChallengeCommand,
    RevokeTokenCommand,
    SetLogDeliveryConfigurationCommand,
    SetRiskConfigurationCommand,
    SetUICustomizationCommand,
    SetUserMFAPreferenceCommand,
    SetUserPoolMfaConfigCommand,
    SetUserSettingsCommand,
    SignUpCommand,
    StartUserImportJobCommand,
    StopUserImportJobCommand,
    TagResourceCommand,
    UntagResourceCommand,
    UpdateAuthEventFeedbackCommand,
    UpdateDeviceStatusCommand,
    UpdateGroupCommand,
    UpdateIdentityProviderCommand,
    UpdateResourceServerCommand,
    UpdateUserAttributesCommand,
    UpdateUserPoolCommand,
    UpdateUserPoolClientCommand,
    UpdateUserPoolDomainCommand,
    VerifySoftwareTokenCommand,
    VerifyUserAttributeCommand,
};
export class CognitoIdentityProvider extends CognitoIdentityProviderClient {
}
createAggregatedClient(commands, CognitoIdentityProvider);
